cs.ns('app.ui.composite.osc')
app.ui.composite.osc.ctrl = cs.clazz({
    extend:   app.fw.abstract.ctrl,
    dynamics: {
        channelList: null,
        contentList: null
    },
    protos:   {

        create () {
            this.base(app.ui.composite.osc.model, app.ui.composite.osc.view)
            cs(this).property('ComponentJS:state-auto-increase', true)

            this.channelList = this.view.create('channelList', app.ui.widget.list.ctrl)
            this.contentList = this.view.create('contentList', app.ui.widget.list.ctrl)
        },

        prepare () {
            this.base()

            this.observeOwnModel('event:toggleChannelList', () => {
                if (this.model.value('state:channelVisible'))
                    this.model.value('data:menuContent', this.model.value('data:menuContent') !== 'channel' ? 'channel' : '')
            })

            this.observeOwnModel('event:toggleContentList', () => {
                if (this.model.value('state:contentVisible'))
                    this.model.value('data:menuContent', this.model.value('data:menuContent') !== 'content' ? 'content' : '')
            })

            this.observeOwnModel('event:toggleQuickjump', () => {
                this.model.value('event:directEntry', true)
            })

            this.observeOwnModel('event:toggleAccess', () => {
                if (!this.model.value('global:state:embedded')) {
                    if (this.model.value('global:state:anonymous'))
                        this.model.value('event:login', true)
                    else
                        this.model.value('event:logout', true)
                }
            })

            this.observeOwnModel('event:backward', () => {
                this.publishEventToParent('selectPreviousContent')
            })

            this.observeOwnModel('event:forward', () => {
                this.publishEventToParent('selectNextContent')
            })

            this.observeOwnModel('event:pause', () => {
                this.publishEventToParent('pauseContent')
            })

            this.observeOwnModel('event:play', () => {
                this.publishEventToParent('resumeContent')
            })

            this.observeOwnModel('event:login', () => {
                this.publishEventToParent('activateLogin')
            })

            this.observeOwnModel('event:logout', () => {
                this.publishEventToParent('anonymousLogin')
            })

            this.observeOwnModel('event:directEntry', () => {
                this.publishEventToParent('activateDirectEntry')
            })

            this.observeOwnModel('event:requestFullscreen', () => {
                this.publishEventToParent('requestFullscreen')
            })

            this.observeOwnModel('event:exitFullscreen', () => {
                this.publishEventToParent('exitFullscreen')
            })

            // data observer to prevent model change in specific cases
            this.observeOwnModel('data:menuContent', (ev/*, value*/) => {
                if (this.model.value('global:state:embedded'))
                    ev.result("");
            })

            this.observeParentModel('global:data:channels', (ev, channels) => {
                this.channelList.call('resetListEntries')
                this.channelList.call('selectedEntry', -1)
                const selectedChannel = this.model.value('global:data:selectedChannel')
                let found             = false
                _.forEach(channels, (channel) => {
                    if (channel.publicVisible) {
                        this.channelList.call('addListEntry', {
                            id:      channel.hash,
                            name:    channel.title,
                            desc:    channel.subtitle,
                            clazz:   'channel',
                            clickId: channel.hash
                        })
                    }
                    if (selectedChannel && selectedChannel.hash === channel.hash)
                        found = true
                })
                if (selectedChannel && !found)
                    this.model.value('global:data:selectedChannel', null)
            }, { boot: true })

            this.observeParentModel('global:data:contents', (ev, contents) => {
                this.contentList.call('resetListEntries')
                this.contentList.call('selectedEntry', -1)
                this.model.value('state:contentVisible', contents.length > 0 && !this.model.value('global:state:embedded'))
                _.forEach(contents, (content) => {
                    this.contentList.call('addListEntry', {
                        id:      content.hash,
                        name:    content.title,
                        desc:    content.subtitle,
                        clazz:   'content',
                        clickId: content.hash
                    })
                })
            }, { boot: true })

            this.observeParentModel('global:data:selectedChannel', (ev, channel) => {
                this.channelList.call('selectedEntry', channel && channel.hash ? channel.hash : -1)
                if (channel)
                    this.model.value('data:menuContent', 'content')
                else
                    this.model.value('data:menuContent', 'channel')
            }, { boot: true })

            this.observeParentModel('global:data:currentContent', (ev, content) => {
                this.contentList.call('selectedEntry', content && content.hash ? content.hash : -1)
            }, { boot: true })

            this.observeParentModel('global:state:autoplay', (ev, state) => {
                this.model.value('state:channelVisible', !state)
            }, { boot: true, op: 'changed' })

            this.observeParentModel('global:state:embedded', (ev, state) => {
                this.model.value('state:channelVisible', !state && !this.model.value('global:state:autoplay'))
                this.model.value('state:contentVisible', !state && this.model.value('global:data:contents').length > 0 )
            }, { boot: true, op: 'changed' })

            this.observeOwnModel('state:contentVisible', (ev, state) => {
                this.model.value('state:controlsVisible', state || this.model.value('global:state:embedded'))
            }, { boot: true, op: 'changed' })

            this.subscribeForChildEvent('list:selectedEntry', (ev, value) => {
                if (ev.target() === this.channelList) {
                    this.publishEventToParent('selectChannel', value)
                } else if (ev.target() === this.contentList) {
                    this.publishEventToParent('selectContent', value)
                }
            })
        }
    }
})